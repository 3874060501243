@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
    font-family: Epilogue;
    font-weight: 400;
    src: url(../assets/Epilogue-Regular.woff);
}
@font-face {
    font-family: Epilogue;
    font-weight: 500;
    src: url(../assets/Epilogue-Medium.woff);
}
@font-face {
    font-family: Epilogue;
    font-weight: 600;
    src: url(../assets/Epilogue-SemiBold.woff);
}
@font-face {
    font-family: Epilogue;
    font-weight: 700;
    src: url(../assets/Epilogue-Bold.woff);
}
