@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@font-face {
    font-family: Epilogue;
    font-weight: 400;
    src: url(https://static.momence.com/host-landing/static/media/Epilogue-Regular.b09c9ac4.woff);
}
@font-face {
    font-family: Epilogue;
    font-weight: 500;
    src: url(https://static.momence.com/host-landing/static/media/Epilogue-Medium.d9f615c6.woff);
}
@font-face {
    font-family: Epilogue;
    font-weight: 600;
    src: url(https://static.momence.com/host-landing/static/media/Epilogue-SemiBold.b1fc2728.woff);
}
@font-face {
    font-family: Epilogue;
    font-weight: 700;
    src: url(https://static.momence.com/host-landing/static/media/Epilogue-Bold.d442a833.woff);
}

